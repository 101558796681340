import Store from "@/store";

import patientRoutes from "@/router/users/patientRoutes";
import therapistRoutes from "@/router/users/therapistRoutes";
import adminRoutes from "@/router/users/adminRoutes";
import superadminRoutes from "@/router/users/superadminRoutes";
import siteManagerRoutes from "@/router/users/siteManagerRoutes";
import onboardingRoutes from "@/router/onboardingRoutes";
import forgotPasswordRoutes from "@/router/forgotPasswordRoutes";
import dpoRoutes from "@/router/users/DPORoutes";
import requestSAMLSiteRoutes from "@/router/requestSAMLSiteRoutes";
import referenceRoutes from "@/router/referenceRoutes.js";
import footerRoutes from "@/router/footerRoutes.js";
import zoomRoutes from "@/router/zoomRoutes.js";
import navigateRoute from "@/router/navigate.js";

export default [
  {
    path: "/",
    name: "home",
    redirect: () => {
      if (Store.getters["Auth/isPatient"]) {
        return { name: "Patient/Home" };
      }

      if (Store.getters["Auth/isTherapist"]) {
        return {
          name: "Therapist/Home",
        };
      }

      if (Store.getters["Auth/isAdmin"]) {
        return { name: "Admin/Home" };
      }

      if (Store.getters["Auth/isSuperAdmin"]) {
        return { name: "SuperAdmin/Home" };
      }

      if (Store.getters["Auth/isDPO"]) {
        return { name: "DPO/Home" };
      }

      if (Store.getters["Auth/isSiteManager"]) {
        return { name: "SiteManager/Home" };
      }
      return { name: "login" };
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/CommonPages/LoginPage/index.vue"),
    beforeEnter: (to, from, next) => {
      if (Store.getters["Auth/hasAccessToken"]) {
        next({ name: "home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/login/saml/:samlSiteName",
    name: "loginsaml",
    component: () => import("@/pages/CommonPages/LoginSAMLPage/index.vue"),
    props: (route) => ({
      samlSiteName: route.params.samlSiteName,
      errorKey: route.query.errorKey,
      technicalErrorCode: route.query.technicalErrorCode,
    }),
    beforeEnter: (to, from, next) => {
      if (Store.getters["Auth/hasAccessToken"]) {
        next({ name: "home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/pages/CommonPages/LogoutPage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login-two-factor",
    name: "login-two-factor",
    component: () => import("@/pages/CommonPages/LoginPage/LoginTwoFactor.vue"),
    beforeEnter: (to, from, next) => {
      if (
        Store.getters["Auth/hasAccessToken"] ||
        !to.params.password ||
        !to.params.email
      ) {
        next({ name: "home" });
      } else {
        next();
      }
    },
    props: (route) => ({
      email: route.params.email,
      password: route.params.password,
    }),
  },
  ...patientRoutes,
  ...therapistRoutes,
  ...adminRoutes,
  ...superadminRoutes,
  ...siteManagerRoutes,
  ...onboardingRoutes,
  ...forgotPasswordRoutes,
  ...dpoRoutes,
  ...requestSAMLSiteRoutes,
  ...zoomRoutes,
  ...referenceRoutes,
  ...footerRoutes,
  ...navigateRoute,

  // and finally the default route, when none of the above matches:
  {
    path: "*",
    name: "404",
    redirect: () => {
      return { name: "login" };
    },
  },
];
