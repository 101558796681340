import Vue from "vue";
import Router from "vue-router";

import Store from "@/store/";

import routes from "./routes.js";

import { trackPageView } from "@/plugins/vueApplicationInsights";
import { getAccessTokenCookie } from "@/utils/cookies";
import { demoPatientUsername } from "@/services/users.js";
import {
  attemptAutoLogin,
  setUserInformationAndRights,
} from "@/services/auth.js";
import { userApi } from "@/services/api.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/**
 * Check the permissions of a user and route
 * @param {object} to The route to navigate to
 */
const checkPermissions = (to) => {
  if (
    to.matched.some((m) => m.meta.requiresAuth) &&
    !Store.getters["Auth/hasAccessToken"]
  ) {
    return false;
  }

  if (
    to.matched.some((m) => m.meta.requiresPatient) &&
    !Store.getters["Auth/isPatient"]
  ) {
    return false;
  }

  if (
    to.matched.some((m) => m.meta.requiresTherapist) &&
    !Store.getters["Auth/isTherapist"]
  ) {
    return false;
  }

  if (
    to.matched.some((m) => m.meta.requiresAdmin) &&
    !Store.getters["Auth/isAdmin"]
  ) {
    return false;
  }

  if (
    to.matched.some((m) => m.meta.requiresDPO) &&
    !Store.getters["Auth/isDPO"]
  ) {
    return false;
  }

  if (
    to.matched.some((m) => m.meta.requiresSuperAdmin) &&
    !Store.getters["Auth/isSuperAdmin"]
  ) {
    return false;
  }

  if (
    to.matched.some((m) => m.meta.requiresSuperTherapist) &&
    !Store.getters["Auth/isSuperTherapist"]
  ) {
    return false;
  }

  if (
    to.matched.some((m) => m.meta.requiresSiteManager) &&
    !Store.getters["Auth/isSiteManager"]
  ) {
    return false;
  }

  if (
    to.matched.some((m) => m.meta.requiresMindMazeAuth) &&
    !Store.getters["Auth/isSuperAdmin"] &&
    !Store.getters["Auth/isAdmin"]
  ) {
    return false;
  }

  return true;
};

/**
 * Check the access token and reloads it from the cookies if available.
 * @returns {boolean} Whether the user has an access token
 */
const checkLogin = async () => {
  // If we have at least a token,
  // and we are considered not logged in,
  // let's check if our token is valid
  const CookieAccessToken = getAccessTokenCookie();
  if (CookieAccessToken && !Store.getters["Auth/hasAccessToken"]) {
    await Store.dispatch("Auth/setAccessToken", CookieAccessToken);

    return await userApi
      .get("profile")
      .then((response) => {
        return setUserInformationAndRights(response.data.result).then(() => {
          return true;
        });
      })
      .catch(() => {
        Store.dispatch("Auth/clearAll").finally(() => {
          return false;
        });
      });
  }

  // By default, we just return the state of the store
  return Store.getters["Auth/hasAccessToken"];
};

const privacyPolicyIsRequired = () => {
  return Store.getters["Auth/isPrivacyPolicySignatureRequired"];
};

const consentIsRequired = () => {
  return Store.getters["Auth/isConsentFormSignatureRequired"];
};

/**
 * Moves the user to the logout page,
 * which then manages a standard logout action sequence
 */
const goToLogout = () => {
  return router.push({ name: "logout" }).catch();
};

/**
 * Move directly the user back to the login screen after clearing user data
 * (without going to the logout page first)
 * @param to the current path the user wanted to display
 * @param next the next route handler
 */
const goToLogin = (to, next) => {
  Store.commit("Auth/clearAll");

  // Add the previous path to the query so that we go back to it once logged back in.
  let query = to.query;
  query.previousPath = to.path;

  next({ name: "login", query: query });
};

router.beforeEach(async (to, from, next) => {
  // track routes into insights
  if (to.name === "loginsaml") {
    trackPageView(`LoginPage.LoginSAML`);
  }
  /**
   * Use route name for patients pages
   * Except for demopatient which is identified by the path
   */
  if (!to.path.includes(demoPatientUsername)) {
    switch (to.name) {
      case "Therapist/Patient/Plan":
        trackPageView(`PatientManagement.Plan`);
        break;
      case "Therapist/Patient/Teleconsultation":
        trackPageView(`PatientManagement.Teleconsultation`);
        break;
      case "Therapist/Patient/Monitor":
        trackPageView(`PatientManagement.Monitor`);
        break;
      case "Therapist/Patient/Review":
        trackPageView(`PatientManagement.Review`);
        break;
      case "Therapist/Patient/Assessment":
        trackPageView(`PatientManagement.Assessment`);
        break;
      case "Therapist/Patient/Profile":
        trackPageView(`PatientManagement.Profile`);
        break;
      case "Therapist/Patient/Dashboard":
        trackPageView(`PatientManagement.Dashboard`);
        break;
      case "Therapist/Patient/EPRO":
        trackPageView(`PatientManagement.EPRO`);
        break;
      default:
        break;
    }
  }
  switch (to.path) {
    case "/patient-list":
      trackPageView(`PatientList`);
      break;
    case "/meetings":
      trackPageView(`Meetings`);
      break;
    case "/new-patient":
      trackPageView(`NewPatient`);
      break;
    case "/templates":
      trackPageView(`Templates`);
      break;
    case "/training/playlists":
      trackPageView(`TrainingMaterial`);
      break;
    case "/logout":
      trackPageView(`LoginPage.Logout`);
      break;
    case "/login":
      trackPageView(`LoginPage.Login`);
      break;
    case "/reset-password/request":
      trackPageView(`LoginPage.ForgotPassword`);
      break;
    case "/request-SAML-site":
      trackPageView(`LoginPage.ForgotSAMLSite`);
      break;
    case "/epro-patients":
      trackPageView(`EPROPatientList`);
      break;
    /* Therapist, administration pages */
    case "/therapist-admin/therapists":
      trackPageView(`User.AdministrationPage.TherapistList`);
      break;
    case "/therapist-admin/site-managers":
      trackPageView(`User.AdministrationPage.SiteManagerList`);
      break;
    case "/therapist-admin/license":
      trackPageView(`User.AdministrationPage.License`);
      break;
    case "/therapist-admin/site-parameters":
      trackPageView(`User.AdministrationPage.SiteManagement`);
      break;
    case "/therapist-admin/site-units":
      trackPageView(`User.AdministrationPage.SiteUnitList`);
      break;
    case "/therapist-admin/patient-training-data":
      trackPageView(`User.AdministrationPage.ExportTrainingData`);
      break;
    case "/therapist-admin/personal-access-token":
      trackPageView(`User.AdministrationPage.PersonalAccessToken`);
      break;
    /* Demo patient */
    case "/patient/demopatient/plan":
      trackPageView(`DemoPatient.Plan`);
      break;
    case "/patient/demopatient/teleconsultation":
      trackPageView(`DemoPatient.Teleconsultation`);
      break;
    case "/patient/demopatient/monitor":
      trackPageView(`DemoPatient.Monitor`);
      break;
    case "/patient/demopatient/review":
      trackPageView(`DemoPatient.Review`);
      break;
    case "/patient/demopatient/assessment":
      trackPageView(`DemoPatient.Assessment`);
      break;
    case "/patient/demopatient/profile":
      trackPageView(`DemoPatient.Profile`);
      break;
    case "/patient/demopatient/epro":
      trackPageView(`DemoPatient.EPRO`);
      break;
    /* Admin */
    case "/admin/administrator":
      trackPageView(`Admin.AdministrationPage.AdminManagement`);
      break;
    case "/admin/site-management":
      trackPageView(`Admin.AdministrationPage.SiteManagement`);
      break;
    case "/admin/site-manager":
      trackPageView(`Admin.AdministrationPage.SiteManager`);
      break;
    case "/admin/therapist":
      trackPageView(`Admin.AdministrationPage.Therapist`);
      break;
    case "/admin/patient-management":
      trackPageView(`Admin.AdministrationPage.PatientManagement`);
      break;
    case "/admin/archived-users":
      trackPageView(`Admin.AdministrationPage.ArchivedUsers`);
      break;
    case "/admin/customers":
      trackPageView(`Admin.AdministrationPage.Customers`);
      break;
    case "/admin/license":
      trackPageView(`Admin.AdministrationPage.Licenses`);
      break;
    case "/admin/patient-license":
      trackPageView(`Admin.AdministrationPage.PatientLicenses`);
      break;
    case "/admin/optional-feature-activation":
      trackPageView(`Admin.AdministrationPage.OptionalFeatures`);
      break;
    case "/admin/data-export":
      trackPageView(`Admin.AdministrationPage.DataUserExport`);
      break;
    case "/admin/feedbackReport":
      trackPageView(`Admin.AdministrationPage.FeedbackReports`);
      break;
    case "/admin/asset":
      trackPageView(`Admin.AdministrationPage.Assets`);
      break;
    case "/admin/asset/new":
      trackPageView(`Admin.AdministrationPage.Assets.CreateAsset`);
      break;
    /* Site manager */
    case "/therapists":
      trackPageView(`SiteManager.TherapistList`);
      break;
    case "/site-managers":
      trackPageView(`SiteManager.SiteManagerList`);
      break;
    case "/license":
      trackPageView(`SiteManager.License`);
      break;
    case "/site-parameters":
      trackPageView(`SiteManager.SiteManagement`);
      break;
    case "/site-units":
      trackPageView(`SiteManager.SiteUnitList`);
      break;
    case "/personal-access-token":
      trackPageView(`SiteManager.PersonalAccessToken`);
      break;
    default:
      break;
  }

  // Training Materials
  if (to.path.includes("/training/playlists/office-hours")) {
    trackPageView(`TrainingMaterial.OfficeHours`);
  } else if (to.path.includes("/training/playlists/clinical-tips")) {
    trackPageView(`TrainingMaterial.ClinicalTips`);
  } else if (to.path.includes("/training/playlists/mindmotion-key-concepts")) {
    trackPageView(`TrainingMaterial.MindMotionKeyConcepts`);
  }

  // Profile pages
  if (to.path.includes("profile")) {
    trackPageView(`Profile`);
  }

  // Support pages
  if (to.path.includes("support/contact-us")) {
    trackPageView(`SupportPage.ContactUs`);
  }
  if (to.path.includes("support/faq")) {
    trackPageView(`SupportPage.FrequentlyAskedQuestions`);
  }
  if (to.path.includes("support/ifu")) {
    trackPageView(`SupportPage.InstructionsForUse`);
  }

  // Check here we are not trying to auto-login first.
  if (
    to.params.pathMatch !== undefined &&
    to.params.pathMatch.includes("autologin") &&
    to.query !== undefined &&
    to.query.token !== undefined
  ) {
    // Provide token only and check for token existence first
    await attemptAutoLogin(to.query.token)
      .then((_) => {
        // Remove the one time token as it is now useless and would only clutter the url.
        delete to.query.token;
      })
      .catch((_) => {
        // Remove the token as it is not a good one.
        delete to.query.token;
        goToLogin(to, next);
        return;
      });
  }

  await checkLogin()
    .then((isLogged) => {
      // First check whether we have the right to go to the desired destination
      if (!checkPermissions(to)) {
        // Console error for development builds
        if (process.env.NODE_ENV === "development") {
          console.error(`Permission error while trying to navigate to:`, to);
        }
        if (isLogged && !to.name?.includes("home")) {
          // Then check whether we can go to the default destination
          // and invalidate the previous path
          delete to.query.previousPath;
          next({ name: "home", query: to.query });
          return;
        } else {
          // If everything fails, go back to login
          goToLogin(to, next);
          return;
        }
      }

      if (isLogged) {
        if (privacyPolicyIsRequired()) {
          if (!to.path.includes("onboarding/privacy-policy")) {
            // Re-route to privacy but keep the potential query parameters and former path
            next({ name: "onboarding/privacy-policy", query: to.query });
            return;
          } else {
            next();
            return;
          }
        }
        if (consentIsRequired()) {
          if (!to.path.includes("onboarding/consent-form")) {
            // Re-route to consent but keep the potential query parameters
            next({ name: "onboarding/consent-form", query: to.query });
            return;
          } else {
            next();
            return;
          }
        }
      }

      // Handles the requested redirection once logged in and the legal documents are signed
      // Also don't redirect when attempting to go to login as it is already handled.
      if (
        to.query !== undefined &&
        to.query.redirect !== undefined &&
        !to.query.redirect.includes("login")
      ) {
        next({ path: to.query.redirect });
        return;
      }

      // Otherwise simply follow the next route
      next();
    })
    .catch((error) => {
      if (process.env.NODE_ENV === "development") {
        console.log(error);
      }
    });
});

router.onError((error) => {
  if (/ChunkLoadError./i.test(error.message)) {
    window.location.reload();
  }
});

export { router as default, goToLogout };
