import Profile from "@/models/Profile.model.js";
import {
  setSessionStorageDisableTeleconsultation,
  setSessionStorageDisableSideBar,
} from "@/store/sessionStorage.js";

/**
 * Handles navigation using a common redirection route:
 * Example: <baseUrl>/navigate?patientSiteId=thisPatient&name=dashboard&disableSidebar=true
 * --> /patient/<patientUsername>/dashboard (without sidebar)
 */

/// Patient convenience route map for therapists
const PatientRouteMap = Object.freeze({
  dashboard: "Therapist/Patient/Dashboard",
  plan: "Therapist/Patient/Plan",
  teleconsultation: "Therapist/Patient/Teleconsultation",
  monitor: "Therapist/Patient/Monitor",
  review: "Therapist/Patient/Review",
  assessment: "Therapist/Patient/Assessment",
  epro: "Therapist/Patient/EPRO",
  profile: "Therapist/Patient/Profile",
});

export default [
  {
    path: "/navigate",
    name: "Navigation",
    // N.B.: redirect() doesn't support async according to:
    // https://github.com/vuejs/vue-router/issues/2729 and others
    // therefore we use beforeEnter()
    beforeEnter: async (to, from, next) => {
      // Parameters
      const params = to.query;
      const routeName = PatientRouteMap[params.name] || "home";
      // Redirecting to the default route ("home") when no parameters, removing query parameters
      if (routeName === "home") {
        return next({ name: "home", query: {} });
      }

      const patientSiteId = params.patientSiteId || "";
      const disableSidebar = params.disableSidebar || undefined;
      const disableTeleconsultation =
        params.disableTeleconsultation || undefined;

      const redirection = {
        name: routeName,
        query: {}, // clean query when redirecting
      };

      // Handle parameters
      if (disableSidebar != undefined) {
        setSessionStorageDisableSideBar(disableSidebar);
      }
      if (disableTeleconsultation != undefined) {
        setSessionStorageDisableTeleconsultation(disableTeleconsultation);
      }

      if (patientSiteId) {
        await Profile.getPatients();
        const patient = Profile.query()
          .where((p) => p.patientSiteId === patientSiteId)
          .first();
        if (patient) {
          const patientUsername = patient.userName;
          redirection.params = { username: patientUsername };
          return next(redirection);
        }
      }
    },
  },
];
