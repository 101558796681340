// Data stored in the session storage
const disableTeleconsultationInStorageName = "mm_disable_teleconsultation";
const disableSideBarInStorageName = "mm_disable_sidebar";

// Disable teleconsultation
export function setSessionStorageDisableTeleconsultation(
  disableTeleconsultation
) {
  window.sessionStorage.setItem(
    disableTeleconsultationInStorageName,
    disableTeleconsultation
  );
}

export function getSessionStorageDisableTeleconsultation() {
  return JSON.parse(
    window.sessionStorage.getItem(disableTeleconsultationInStorageName)
  );
}

// Disable sidebar
export function setSessionStorageDisableSideBar(disableSideBar) {
  window.sessionStorage.setItem(disableSideBarInStorageName, disableSideBar);
}

export function getSessionStorageDisableSideBar() {
  return JSON.parse(window.sessionStorage.getItem(disableSideBarInStorageName));
}
